/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContratLocationGen } from './contrat-location.gen.model';
import { BoxGen } from './box.gen.model';
import { ClientGen } from './client.gen.model';
import { TypeAvenantGen } from './type-avenant.gen.model';
import { ItemAvenantGen } from './item-avenant.gen.model';


export interface AvenantGen { 
    ajouterBox?: boolean;
    ancienBox?: BoxGen;
    ancienClient?: ClientGen;
    commentaire1?: string;
    commentaire2?: string;
    contrat: ContratLocationGen;
    created: Date;
    createdBy: string;
    dateAvenant: Date;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    listItemAvenant?: Set<ItemAvenantGen>;
    nbBadgeRemis?: number;
    nbBadgeRendu?: number;
    nouveauBox?: BoxGen;
    nouveauClient?: ClientGen;
    numero: number;
    numerosBadgeRemis?: string;
    numerosBadgeRendu?: string;
    prixRemiseHt?: number;
    remise?: number;
    typeAvenant: TypeAvenantGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class AvenantGen_ {
  static readonly ajouterBox = 'ajouterBox';
  readonly ajouterBox = 'ajouterBox';
  static readonly ancienBox = 'ancienBox';
  readonly ancienBox = 'ancienBox';
  static readonly ancienClient = 'ancienClient';
  readonly ancienClient = 'ancienClient';
  static readonly commentaire1 = 'commentaire1';
  readonly commentaire1 = 'commentaire1';
  static readonly commentaire2 = 'commentaire2';
  readonly commentaire2 = 'commentaire2';
  static readonly contrat = 'contrat';
  readonly contrat = 'contrat';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateAvenant = 'dateAvenant';
  readonly dateAvenant = 'dateAvenant';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listItemAvenant = 'listItemAvenant';
  readonly listItemAvenant = 'listItemAvenant';
  static readonly nbBadgeRemis = 'nbBadgeRemis';
  readonly nbBadgeRemis = 'nbBadgeRemis';
  static readonly nbBadgeRendu = 'nbBadgeRendu';
  readonly nbBadgeRendu = 'nbBadgeRendu';
  static readonly nouveauBox = 'nouveauBox';
  readonly nouveauBox = 'nouveauBox';
  static readonly nouveauClient = 'nouveauClient';
  readonly nouveauClient = 'nouveauClient';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly numerosBadgeRemis = 'numerosBadgeRemis';
  readonly numerosBadgeRemis = 'numerosBadgeRemis';
  static readonly numerosBadgeRendu = 'numerosBadgeRendu';
  readonly numerosBadgeRendu = 'numerosBadgeRendu';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly typeAvenant = 'typeAvenant';
  readonly typeAvenant = 'typeAvenant';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new AvenantGen_();
  }
}

