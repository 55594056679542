/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EtatDevisGen = 'CREE' | 'ENVOYE' | 'REFUSE' | 'ACCEPTE' | 'EN_ATTENTE' | 'ANNULE';

export const EtatDevisGen = {
        CREE: 'CREE' as EtatDevisGen,
        ENVOYE: 'ENVOYE' as EtatDevisGen,
        REFUSE: 'REFUSE' as EtatDevisGen,
        ACCEPTE: 'ACCEPTE' as EtatDevisGen,
        EN_ATTENTE: 'EN_ATTENTE' as EtatDevisGen,
        ANNULE: 'ANNULE' as EtatDevisGen
        };

export class EtatDevisGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new EtatDevisGen_();
}
}

