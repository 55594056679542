import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessageService} from '../service/message.service';
import {DisplaySettingsModel} from './display-settings.service';
import {Router} from '@angular/router';
import {isBlank} from '../utils/string-util';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private messageService: MessageService,
              private router: Router,
              private displaySettingsModel: DisplaySettingsModel) {
  }

  private static getMessagePertinent(error: HttpErrorResponse): any {
    if (error.status === 403 && error.url.endsWith('/api/login')) {
      return 'Erreur sur identifiant ou mot de passe';
    }

    const list: string[] =
      [error.error?.cause?.mostSpecificCause?.message,
        error.error?.message,
        error.error,
        error.message,
        'Erreur technique'];

    return list.find(txt => !isBlank(txt));
  }

  /**
   * Interceptor
   * @return Observable<HttpEvent<any>>
   * @param request HttpRequest<any>
   * @param next HttpHandler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.displaySettingsModel.displaySpinner(true);
    const authorization = sessionStorage.getItem('jwt_token') ? sessionStorage.getItem('jwt_token') : '';
    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        authorization
      }
    });

    // Gestion de l'affichage des erreurs dans le front
    const showErrorHeader = request.headers.get('show-error');
    const showError = showErrorHeader == null || showErrorHeader !== 'false';

    return next.handle(request) // add error handling
      .pipe(catchError(
        (error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => this.handleError(error, showError)
      ));
  }

  handleError(error: HttpErrorResponse, showError: boolean): any {
    // On enlève le spinner pour ne pas qu'il spin à l'infini
    this.displaySettingsModel.displaySpinner(false);

    // cas spécifique :
    if (error.status.toString().startsWith('4')) {
      return this.handleError4xx(showError, error);
    }
    if (error.status === 504) {
      this.messageService.showError('Le backend n\'est pas joignable (erreur proxy ?) !')
      //this.router.navigate(['/auth/connexion']);
      return throwError(error);
    }

    if (error.status === undefined) {
      this.messageService.showError(error.message);
    } else if (error.status !== 0) {
      if (showError) {
        this.messageService.showError('Une erreur technique est survenue');
      }
      console.error(error);
    }
    return throwError(error);
  }

  private handleError4xx(showError: boolean, error: HttpErrorResponse): any {
    const errorMessage = HttpInterceptorService.getMessagePertinent(error);

    if (error.status === 401) {
      this.messageService.showError('Votre session a expiré, vous devez vous ré-authentifier');
      console.log('handleError4xx code 401 -> écran de connexion');
      this.router.navigate(['/auth/connexion']);
      return;
    }

    if (error.status === 403) {
      this.messageService.showError('Vous n\'avez pas les droits pour effectuer cette action');
      return;
    }

    if (showError) {
      if (errorMessage instanceof Blob) {
        const reader = new FileReader();
        // tslint:disable-next-line:only-arrow-functions
        reader.onload = (function (messageService): any {
          return function (): void {
            messageService.showError(this.result);
          };
        })(this.messageService);
        reader.readAsText(errorMessage);
      } else {
        this.messageService.showError(errorMessage);
      }
    }

    return throwError(error);
  }
}
