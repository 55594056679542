export * from './activite-client.gen.service';
import { ActiviteClientServiceGen } from './activite-client.gen.service';
export * from './app-config.gen.service';
import { AppConfigServiceGen } from './app-config.gen.service';
export * from './article.gen.service';
import { ArticleServiceGen } from './article.gen.service';
export * from './audit.gen.service';
import { AuditServiceGen } from './audit.gen.service';
export * from './audit-author.gen.service';
import { AuditAuthorServiceGen } from './audit-author.gen.service';
export * from './avenant.gen.service';
import { AvenantServiceGen } from './avenant.gen.service';
export * from './box.gen.service';
import { BoxServiceGen } from './box.gen.service';
export * from './civilite.gen.service';
import { CiviliteServiceGen } from './civilite.gen.service';
export * from './client.gen.service';
import { ClientServiceGen } from './client.gen.service';
export * from './client-famille.gen.service';
import { ClientFamilleServiceGen } from './client-famille.gen.service';
export * from './client-sous-famille.gen.service';
import { ClientSousFamilleServiceGen } from './client-sous-famille.gen.service';
export * from './contrat-location.gen.service';
import { ContratLocationServiceGen } from './contrat-location.gen.service';
export * from './devis-location.gen.service';
import { DevisLocationServiceGen } from './devis-location.gen.service';
export * from './document.gen.service';
import { DocumentServiceGen } from './document.gen.service';
export * from './equipement.gen.service';
import { EquipementServiceGen } from './equipement.gen.service';
export * from './etat-devis.gen.service';
import { EtatDevisServiceGen } from './etat-devis.gen.service';
export * from './etat-facture.gen.service';
import { EtatFactureServiceGen } from './etat-facture.gen.service';
export * from './facture.gen.service';
import { FactureServiceGen } from './facture.gen.service';
export * from './item-avenant.gen.service';
import { ItemAvenantServiceGen } from './item-avenant.gen.service';
export * from './item-contrat.gen.service';
import { ItemContratServiceGen } from './item-contrat.gen.service';
export * from './item-devis.gen.service';
import { ItemDevisServiceGen } from './item-devis.gen.service';
export * from './item-facture.gen.service';
import { ItemFactureServiceGen } from './item-facture.gen.service';
export * from './mode-paiement.gen.service';
import { ModePaiementServiceGen } from './mode-paiement.gen.service';
export * from './mois.gen.service';
import { MoisServiceGen } from './mois.gen.service';
export * from './niveau.gen.service';
import { NiveauServiceGen } from './niveau.gen.service';
export * from './oui-non.gen.service';
import { OuiNonServiceGen } from './oui-non.gen.service';
export * from './periode-paiement.gen.service';
import { PeriodePaiementServiceGen } from './periode-paiement.gen.service';
export * from './position-porte.gen.service';
import { PositionPorteServiceGen } from './position-porte.gen.service';
export * from './relance-devis.gen.service';
import { RelanceDevisServiceGen } from './relance-devis.gen.service';
export * from './reservation.gen.service';
import { ReservationServiceGen } from './reservation.gen.service';
export * from './role.gen.service';
import { RoleServiceGen } from './role.gen.service';
export * from './sci.gen.service';
import { SciServiceGen } from './sci.gen.service';
export * from './site.gen.service';
import { SiteServiceGen } from './site.gen.service';
export * from './statistique.gen.service';
import { StatistiqueServiceGen } from './statistique.gen.service';
export * from './statut-box.gen.service';
import { StatutBoxServiceGen } from './statut-box.gen.service';
export * from './statut-client.gen.service';
import { StatutClientServiceGen } from './statut-client.gen.service';
export * from './taux-tgc.gen.service';
import { TauxTgcServiceGen } from './taux-tgc.gen.service';
export * from './type-avenant.gen.service';
import { TypeAvenantServiceGen } from './type-avenant.gen.service';
export * from './type-box.gen.service';
import { TypeBoxServiceGen } from './type-box.gen.service';
export * from './type-devis.gen.service';
import { TypeDevisServiceGen } from './type-devis.gen.service';
export * from './type-document.gen.service';
import { TypeDocumentServiceGen } from './type-document.gen.service';
export * from './utilisateur.gen.service';
import { UtilisateurServiceGen } from './utilisateur.gen.service';
export * from './vue-box.gen.service';
import { VueBoxServiceGen } from './vue-box.gen.service';
export const APIS = [ActiviteClientServiceGen, AppConfigServiceGen, ArticleServiceGen, AuditServiceGen, AuditAuthorServiceGen, AvenantServiceGen, BoxServiceGen, CiviliteServiceGen, ClientServiceGen, ClientFamilleServiceGen, ClientSousFamilleServiceGen, ContratLocationServiceGen, DevisLocationServiceGen, DocumentServiceGen, EquipementServiceGen, EtatDevisServiceGen, EtatFactureServiceGen, FactureServiceGen, ItemAvenantServiceGen, ItemContratServiceGen, ItemDevisServiceGen, ItemFactureServiceGen, ModePaiementServiceGen, MoisServiceGen, NiveauServiceGen, OuiNonServiceGen, PeriodePaiementServiceGen, PositionPorteServiceGen, RelanceDevisServiceGen, ReservationServiceGen, RoleServiceGen, SciServiceGen, SiteServiceGen, StatistiqueServiceGen, StatutBoxServiceGen, StatutClientServiceGen, TauxTgcServiceGen, TypeAvenantServiceGen, TypeBoxServiceGen, TypeDevisServiceGen, TypeDocumentServiceGen, UtilisateurServiceGen, VueBoxServiceGen];
