<ascore-card [title]="title" [status]="status"
             [header]="header" [customClose]="customClose" (closeTriggered)="close()"
             [options]="true" [history]="infoHistorique" (keyup.control.enter)="save()"
             [customTitle]="customTitle" *ngIf="loaded" [forceShowHistory]="forceShowHistory">

  <form [formGroup]="form"
        (keydown.enter)="$event.preventDefault()"
        (ngSubmit)="save()"
        class="detail-form flex-fill">

    <div class="d-flex ascore-content-container" [ngClass]="(disablePadding?'':'mb-4 ml-4 mr-4')"
         [class.flex-fill]="!tabs || tabs.length === 0">
      <ng-content></ng-content>
    </div>

    <div *ngIf="form && tabs && tabs.length > 0" class="navbar-container">
      <ascore-navbar [form]="form" [tabs]="tabs"></ascore-navbar>
    </div>

    <div class="ascore-footer btn-container pt-1 pb-2" *ngIf="toolbarVisible===true">
      <button *ngIf="!readOnly"
              class="btn btn-primary"
              (click)="save()"
              type="submit">
        <fa-icon [icon]="faSave"></fa-icon>
        {{ this.exitAfterUpdate ? libelleEnregistrerEtFermer : 'Enregistrer'}}
      </button>

      <button class="btn btn-outline-secondary ml-2"
              (click)="this.cancel()"
              type="button">
        <fa-icon [icon]="faTimes"></fa-icon>
        Fermer
      </button>

    </div>
  </form>
</ascore-card>
<ngx-spinner name="ascore-detail-spinner" [fullScreen]="false" color="#ec1d25"
             type="ball-scale-multiple"></ngx-spinner>
