import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
  selector: 'button:not([autoriseMultipleClick]), a:not([autoriseMultipleClick])'
})
export class PreventMultipleClickDirective implements OnInit, OnDestroy {
  subscription = new Subscription();

  @Input()
  durationDisable = 300;

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {
    const el = this.elRef.nativeElement;
    this.subscription = fromEvent(el, 'click', {capture: true})
      .subscribe((e: any) => {
        setTimeout(() => {
          el.setAttribute('disabled', 'true');
        }, 1)
        setTimeout(() => {
          el.removeAttribute('disabled');
        }, this.durationDisable);
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
