/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemDevisGen } from './item-devis.gen.model';
import { UtilisateurGen } from './utilisateur.gen.model';
import { EtatDevisGen } from './etat-devis.gen.model';
import { ClientGen } from './client.gen.model';
import { DocumentGen } from './document.gen.model';
import { TypeDevisGen } from './type-devis.gen.model';


export interface DevisLocationGen { 
    client: ClientGen;
    conditionsParticulieres?: string;
    conseiller?: UtilisateurGen;
    created: Date;
    createdBy: string;
    dateEmission?: Date;
    dtype?: string;
    email1?: string;
    email2?: string;
    email3?: string;
    email4?: string;
    etat: EtatDevisGen;
    id?: number;
    instanceLabel: string;
    listDocument?: Set<DocumentGen>;
    listItemDevis?: Set<ItemDevisGen>;
    numero: string;
    qualiteRepresentant?: string;
    representePar?: string;
    telephone?: string;
    totalHt?: number;
    totalTgc?: number;
    totalTtc?: number;
    transforme?: boolean;
    transmis?: boolean;
    typeDevis?: TypeDevisGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class DevisLocationGen_ {
  static readonly client = 'client';
  readonly client = 'client';
  static readonly conditionsParticulieres = 'conditionsParticulieres';
  readonly conditionsParticulieres = 'conditionsParticulieres';
  static readonly conseiller = 'conseiller';
  readonly conseiller = 'conseiller';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly email1 = 'email1';
  readonly email1 = 'email1';
  static readonly email2 = 'email2';
  readonly email2 = 'email2';
  static readonly email3 = 'email3';
  readonly email3 = 'email3';
  static readonly email4 = 'email4';
  readonly email4 = 'email4';
  static readonly etat = 'etat';
  readonly etat = 'etat';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listItemDevis = 'listItemDevis';
  readonly listItemDevis = 'listItemDevis';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly qualiteRepresentant = 'qualiteRepresentant';
  readonly qualiteRepresentant = 'qualiteRepresentant';
  static readonly representePar = 'representePar';
  readonly representePar = 'representePar';
  static readonly telephone = 'telephone';
  readonly telephone = 'telephone';
  static readonly totalHt = 'totalHt';
  readonly totalHt = 'totalHt';
  static readonly totalTgc = 'totalTgc';
  readonly totalTgc = 'totalTgc';
  static readonly totalTtc = 'totalTtc';
  readonly totalTtc = 'totalTtc';
  static readonly transforme = 'transforme';
  readonly transforme = 'transforme';
  static readonly transmis = 'transmis';
  readonly transmis = 'transmis';
  static readonly typeDevis = 'typeDevis';
  readonly typeDevis = 'typeDevis';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new DevisLocationGen_();
  }
}

