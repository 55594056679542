import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AsCoreBaseDomain} from '../../models/ascore-base-domain';
import {faEye, faPlus, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {AsCoreConfirmModalComponent} from '../ascore-confirm-modal/ascore-confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AsCoreTab, AsCoreTabAction} from './ascore-tab.model';
import {MessageService} from '../../service/message.service';
import {SortEvent} from '../../directives/sortable-header.directive';
import {ContentChangeEvent, SelectionChangeEvent, SelectTableEvent} from '../ascore-table/ascore-table.model';
import {isArrayLike, isBoolean, isNil, isNumber} from 'lodash';
import {getValueRecursively} from '../../utils/object-util';
import {IdInstanceLabel} from '../../models/id-instance-label';
import {WithSearchAudit} from '../../service/api/with-search-audit';

@Component({
  selector: 'ascore-tab',
  templateUrl: './ascore-tab.component.html',
  styleUrls: ['./ascore-tab.component.scss']
})
export class AsCoreTabComponent implements OnChanges {

  faEye = faEye;
  faTimes = faTimes;
  faPlus = faPlus;
  faTrash = faTrash;

  @ViewChild('btnSupprimerTpl', {static: true}) btnSupprimerTpl: ElementRef;
  @ViewChild('btnOpenTpl', {static: true}) btnOpenTpl: ElementRef;

  @Input()
  tab: AsCoreTab;

  @Input()
  form: FormGroup;

  @Input()
  readonly: boolean;

  @Input()
  tabAffiche: boolean;

  @Input()
  withSearchAudit: WithSearchAudit;

  contentSelection: IdInstanceLabel[] = [];

  constructor(private modalService: NgbModal,
              private messageService: MessageService) {
  }

  select(event: IdInstanceLabel): void {

    if (!event) {
      return;
    }

    if (this.content().map(it => it.id).indexOf(event.id) < 0) {
      this.content().push(event);
    }
  }

  add(): void {
    if (!this.tab.withCreate || this.tab.readonly) {
      return;
    }

    if (this.tab.type === 'composition') {
      const modalSize = isNil(this.tab.modalsize) ? 'xl' : this.tab.modalsize;
      const modal = this.modalService.open(this.tab.addTemplate ? this.tab.addTemplate : this.tab.detailTemplate,
        {size: modalSize, backdrop: 'static'});
      modal.componentInstance.titre = this.tab.titreCreation ? this.tab.titreCreation : 'Création';
      const parent: AsCoreBaseDomain = this.form.getRawValue();
      modal.componentInstance.parent = parent;
      modal.result.then(entity => {
          if (parent.id && !entity.id && !this.tab.skipAutosaveAfterClosePopup) {
            this.tab.withCreate.create(entity).subscribe((result) => {
              this.appendResultToContent(result);
              this.messageService.showSuccess(`Création de ${result.instanceLabel} effectuée`);
            });
          } else {
            this.appendResultToContent(entity);
          }
        },
        () => {
          // Do nothing
        });
    }
  }

  private appendResultToContent(result): void {
    if (isNil(result)) {
      return;
    }
    if (isArrayLike(result)) {
      result.forEach(arrayElement => this.appendResultToContent(arrayElement));
    } else {
      this.content().push(result);
    }
    if (this.tab.customDataChange) {
      this.tab.customDataChange(this.content())
    }
    this.updateFormControlValueAndValidity();
  }

  open(event: SelectTableEvent): void {
    if (this.tab.ignoreOpen != null && this.tab.ignoreOpen()) {
      return;
    }
    if (this.tab.customOpen) {
      this.tab.customOpen(event.entity);
    } else if (this.tab.type === 'composition') {
      if (event.entity.id) {
        this.tab.withRead?.find(event.entity.id).subscribe(entity => {
          this.openModalUpdate(entity, event.index);
        });
      } else {
        this.openModalUpdate(event.entity, event.index);
      }
    }
  }

  openModalUpdate(entity: IdInstanceLabel, index): void {
    if (!this.tab.withUpdate) {
      return;
    }
    const modalSize = isNil(this.tab.modalsize) ? 'xl' : this.tab.modalsize;
    const modal = this.modalService.open(this.tab.detailTemplate, {size: modalSize, backdrop: 'static'});
    modal.componentInstance.titre = this.tab.titreModification ? this.tab.titreModification : 'Modification';
    modal.componentInstance.parent = this.form.getRawValue();
    modal.componentInstance.entity = entity;
    modal.componentInstance.readonly = this.tab.readonly;
    modal.result.then((resultOnClose) => {

        let updatedEntity: AsCoreBaseDomain;
        let doUpdate = true;

        if (resultOnClose instanceof TabUpdateResult) {
          updatedEntity = resultOnClose.entity;
          doUpdate = resultOnClose.doUpdate;
        } else {
          updatedEntity = resultOnClose;
        }

        if (updatedEntity.id && doUpdate) {
          this.tab.withUpdate.update(entity.id, updatedEntity).subscribe((result) => {
            this.content()[index] = result;
            this.updateFormControlValueAndValidity();
            this.messageService.showSuccess(`Mise à jour de ${result.instanceLabel} effectuée`);
          });
        } else {
          this.content()[index] = updatedEntity;
        }
      },
      () => {
        // Do nothing
      });
  }

  // utile pour permettre de s'abonner aux modifications de la liste
  private updateFormControlValueAndValidity(): void {
    this.form?.get(this.tab?.formControlName)?.updateValueAndValidity();
  }

  delete(entity: IdInstanceLabel): void {
    const index = this.getIndexFromId(entity.id);

    if (index >= 0) {
      const modal = this.modalService.open(AsCoreConfirmModalComponent, {backdrop: 'static'});
      if (this.tab.type === 'composition') {
        modal.componentInstance.message = 'Confirmez-vous la suppression de \n\'' + entity.instanceLabel + '\' ?';
      } else if (this.tab.type === 'lien') {
        modal.componentInstance.message = 'Confirmez-vous la suppression du lien avec \n\'' + entity.instanceLabel + '\' ?';
      } else if (this.tab.type === 'consultation') {
        return;
      }
      modal.result.then(() => {
          if (this.tab.type === 'composition') {
            // Suppression en base
            this.tab.withDelete?._delete(entity.id).subscribe(() => {
              this.content().splice(index, 1);
              if (this.tab.customDataChange) {
                this.tab.customDataChange(this.content())
              }
              this.updateFormControlValueAndValidity();
              this.messageService.showSuccess(`Suppression de ${entity.instanceLabel} effectuée`);
            });
          } else {
            this.content().splice(index, 1);
            if (this.tab.customDataChange) {
              this.tab.customDataChange(this.content())
            }
          }
        },
        () => {
          // Do nothing
        });
    }
  }

  content(): IdInstanceLabel[] {
    if (this.form && this.tab.formControlName && this.form.get(this.tab.formControlName)?.value) {
      // TODO : on appelle le addActionColumns ici car le formulaire est chargé, on peut donc appeler la méthode hideDelete()
      // A voir comment gérer ces colonnes supplémentaires d'une manière plus propre.
      this.addActionColumns();
      return this.form.get(this.tab.formControlName).value;
    }
    return [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.addActionColumns();
  }

  private addActionColumns(): void {
    if (this.tab.columns && (this.tab.hideDelete == null || !this.tab.hideDelete())
      && this.tab.columns.map(it => it.header).indexOf('') < 0) {
      this.tab.columns.push({
        header: '',
        fieldName: '',
        sortFieldName: '',
        template: this.btnSupprimerTpl,
        id: 'BTN_SUPPRIMER'
      });
    } else if (this.tab.columns && (this.tab.hideDelete != null && this.tab.hideDelete()) &&
      this.tab.columns.map(it => it.id).indexOf('BTN_SUPPRIMER') > 0) {
      this.tab.columns = this.tab.columns.filter(col => col.id !== 'BTN_SUPPRIMER');
    }
    if (this.tab.columns && this.tab.withOpenIcon && this.tab.columns.map(it => it.header).indexOf('') < 0) {
      this.tab.columns.push({header: '', fieldName: '', sortFieldName: '', template: this.btnOpenTpl});
    }
  }

  getIndexFromId(id: number): number {
    return this.content().map(it => it.id).indexOf(id);
  }

  sort(event: SortEvent): void {
    if (event.direction === 'asc') {
      this.content().sort((a, b) => this.compare(a, b, event.column));
    } else if (event.direction === 'desc') {
      this.content().sort((a, b) => this.compare(b, a, event.column));
    }
  }

  compare(a, b, columnName): number {
    const value1 = getValueRecursively(a, columnName);
    const value2 = getValueRecursively(b, columnName);
    if (isNil(value1) && isNil(value2)) {
      return 0;
    }
    if (isNil(value1)) {
      return -1;
    }
    if (isNil(value2)) {
      return 1;
    }
    if (isNumber(value1) && isNumber(value2)) {
      return value1 === value2 ? 0 : ((value1 < value2) ? -1 : +1);
    }
    if (isBoolean(value1) && isBoolean(value2)) {
      return value1 === value2 ? 0 : (!value1 ? -1 : 1);
    }

    return value1.localeCompare ? value1.localeCompare(value2) : 0;
  }

  private triggerSelectionChange(event: SelectionChangeEvent): void {
    this.contentSelection = event.selection;
    this.tab.customTabActions?.forEach(action => {
      if (action.onSelectionChange !== undefined) {
        action.onSelectionChange(event.selection);
      }
    });
    this.tab.customTabInfos?.forEach(info => {
      if (info.onSelectionChange !== undefined) {
        info.onSelectionChange(event.selection);
      }
    });
  }

  private triggerContentChange(event: ContentChangeEvent): void {
    this.tab.customTabActions?.forEach(action => {
      if (action.onContentChange !== undefined) {
        action.onContentChange(event.content || []);
      }
    });
    this.tab.customTabInfos?.forEach(info => {
      if (info.onContentChange !== undefined) {
        info.onContentChange(event.content || []);
      }
    });
  }

  triggerActionClick(action: AsCoreTabAction): void {
    action.onClick(this.form.getRawValue(), this.content(), this.contentSelection);
  }

  isDataTablePresent(): boolean {
    return this.tab.type !== 'custom' && this.tab.type !== 'audit';
  }
}

export class TabUpdateResult {
  constructor(public entity, public doUpdate) {
  }
}
